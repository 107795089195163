/* Tratamiento header */
.tratamiento_header{width:100%;height:auto;position:relative;}
.tratamiento_header_shadow{position:absolute;z-index:2;top:0;left:0;width:100%;height:80%;background:rgb(0,0,0);background:linear-gradient(180deg,rgba(0,0,0,0.8) 0%,rgba(255,255,255,0)100%);}
.tratamiento_header img{width:100%;height:auto;}
.tratamiento_header_des{position:absolute;top:50%;transform:translateY(-50%);color:#fff;font-weight:900;z-index:3;}
.tratamiento_header_des__t1{font-weight:100;}
.tratamiento_header_des__t2{font-weight:900;}

.description{position:relative;margin-top:-2rem;z-index:2;display:flex;justify-content:center;}
.description_con{background-color:rgba(0, 0, 0, .3);display:flex;width:fit-content;border-radius:.2rem;justify-content:center;}
.description_el{background-color:#fff;margin-right:1rem;border-radius:.2rem;padding:1rem;}
.description_el img{margin:auto;width:3rem;margin-bottom:.5rem;}
.description_el__des{text-align:center;font-weight:800;}

.servicio{display:flex;}
.servicio.servicio_light{background-color:#f8f8f8;}
.servicio.servicio_dark{background-color:#e7e5e5;}
.servicio_img{width:45%;}
.servicio_img img{width:100%;height:auto;}
.servicio_des{width:55%;}
.servicio_des__t1{color:#383838;font-weight:800;margin-bottom:1rem;}
.servicio_des__t2{color:#2d2c2c;font-weight:300;margin-bottom:.5rem;}
.servicio_des__btn{color:#3a3a3a;font-weight:600;margin-top:1rem;cursor:pointer;}

/* Popup info */
.info{position:fixed;top:50%;left:50%;transform:translate(-50%,-50%);width:90%;max-width:800px;padding:2rem;background-color:#fff;border-radius:.2rem;-webkit-box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.5);box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.5);transition:all .5s ease;}
.info_title{font-size:1.5rem;margin-bottom:1rem;font-weight:800;}
.info_text{font-size:1.2rem;margin-bottom:.5rem;font-weight:300;}
.info_text:last-child{margin-bottom:0;}

/* Responsive style */
@media (max-width: 599px){
    .tratamiento_header_des{left:1rem;font-size:2rem;line-height:2.5rem;}
    .tratamiento_header_des__t2{padding-left:2rem;}

    .description_con{padding:1rem;flex-wrap:wrap;width:90%;}
    .description_el{width:calc(50% - .5rem);font-size:.9rem;}
    .description_el:nth-child(1){margin-bottom:1rem;}
    .description_el:nth-child(2){margin-bottom:1rem;}
    .description_el:nth-child(odd){margin-right:.5rem;margin-left:0;}
    .description_el:nth-child(even){margin-right:0;margin-left:.5rem;}
    .description{margin-bottom:6rem;}

    .servicio{flex-wrap:wrap;}
    .servicio_des,.servicio_img{width:100%;}
    .servicio_des{padding:2rem;}
    .servicio_des__t1{font-size:1.6rem;}
    .servicio_des__t2,.servicio_des__btn{font-size:1.1rem;}
    .servicio_light{flex-direction:column-reverse;}

    .info{padding:1rem;}
    .info_title{font-size:1.2rem;}
    .info_text{font-size:.8rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .tratamiento_header_des{left:2rem;font-size:3rem;line-height:3rem;}
    .tratamiento_header_des__t2{padding-left:2rem;}

    .description_con{padding:1rem;flex-wrap:wrap;width:90%;}
    .description_el{width:calc(50% - .5rem);font-size:.9rem;}
    .description_el:nth-child(1){margin-bottom:1rem;}
    .description_el:nth-child(2){margin-bottom:1rem;}
    .description_el:nth-child(odd){margin-right:.5rem;margin-left:0;}
    .description_el:nth-child(even){margin-right:0;margin-left:.5rem;}
    .description{margin-bottom:6rem;}

    .servicio{padding-bottom:3rem;}
    .servicio_img img{margin-top:-3rem;}
    .servicio_des{padding:2rem;}
    .servicio_des__t1{font-size:1.6rem;}
    .servicio_des__t2,.servicio_des__btn{font-size:1.1rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .tratamiento_header_des{left:4rem;font-size:4rem;line-height:4rem;}
    .tratamiento_header_des__t2{padding-left:2rem;}

    .description_con{padding:1rem;}
    .description_el{width:12rem;font-size:.9rem;}
    .description{margin-bottom:6rem;}

    .servicio{padding-bottom:4rem;}
    .servicio_img img{margin-top:-4rem;}
    .servicio_des{padding:3rem;}
    .servicio_des__t1{font-size:2rem;}
    .servicio_des__t2,.servicio_des__btn{font-size:1.2rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .tratamiento_header_des{left:6rem;font-size:4rem;line-height:4rem;}
    .tratamiento_header_des__t2{padding-left:2rem;}

    .description_con{padding:1rem;}
    .description_el{width:14rem;}
    .description{margin-bottom:6rem;}

    .servicio{padding-bottom:4rem;}
    .servicio_img img{margin-top:-4rem;}
    .servicio_des{padding:4rem;}
    .servicio_des__t1{font-size:2rem;}
    .servicio_des__t2,.servicio_des__btn{font-size:1.2rem;}

    .info{padding:2rem;}
}

@media (min-width:1400px){
    .tratamiento_header_des{left:20%;font-size:5rem;line-height:5rem;}
    .tratamiento_header_des__t2{padding-left:2rem;}

    .description_con{padding:1rem;}
    .description_el{width:16rem;padding:2rem;}
    .description{margin-bottom:8rem;}

    .servicio{padding-bottom:6rem;}
    .servicio_img img{margin-top:-6rem;}
    .servicio_des{padding:6rem;}
    .servicio_des__t1{font-size:2.5rem;}
    .servicio_des__t2,.servicio_des__btn{font-size:1.3rem;}
}