/* Global styles */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
*{margin:0;padding:0;box-sizing:border-box;font-family:'Roboto',sans-serif;font-weight:400;}
img, video, iframe, picture{display:block;}
.section{position:relative;}
.content{position:relative;max-width:1300px;margin:auto;}

/* Navigation bar */
.navbar_mobile{display:none;cursor:pointer;}
.navbar_mobile img{width:2.2rem;padding-top:.6rem;}
.navbar{position:absolute;top:0;left:0;width:100%;z-index:3;}
.navbar .content{padding-top:1rem;padding-bottom:1rem;display:flex;align-items:center;justify-content:center;}
.navbar_links{width:30%;display:flex;align-items:center;}
.navbar_links:nth-child(1){justify-content:flex-end;margin-right:2rem;}
.navbar_links:nth-child(3){margin-left:2rem;}
.navbar_links__el{text-decoration:none;color:#fff;font-weight:300;}
.navbar_links__el:nth-child(1){margin-right:1rem;}
.navbar_logo{width:12rem;height:auto;}
.navbar_logo img{width:100%;height:auto;}

.mobile{position:absolute;top:0;right:0;z-index:9;background-color:#2bbbc2;padding:2rem 3rem;width:100%;transform:translateY(-100%);transition:all .5s ease;}
.mobile_close{position:absolute;right:2rem;top:2rem;width:1.5rem;cursor:pointer;}
.mobile.active{transform:translateY(0);}
.mobile_el{display:block;text-decoration:none;color:#fff;margin-bottom:.5rem;font-size:1.2rem;}
.mobile_el:nth-child(6){margin-bottom:1rem;}
.mobile_contact{display:flex;}
.mobile_contact a{margin-right:1rem;}
.mobile_contact a:nth-child(3){margin-right:0;}
.mobile_contact a img{width:2.5rem;}

/* Footer */
.footer{background-color:#2bbbc2;}
.footer .content{display:flex;align-items:center;}
.footer_des{color:#fff;}
.footer_map a, .footer_map img{width:100%;height:auto;}
.footer_des__t1{font-weight:800;}
.footer_des__t2{font-weight:300;margin-bottom:1.5rem;}
.footer_des_social{display:flex;flex-wrap:wrap;}
.footer_des_social_el{width:33.3%;padding:.5rem;}
.footer_des_social_el__icon{width:4rem;margin:auto;margin-bottom:.5rem;}
.footer_des_social_el__des{text-align:center;}
.footer_des_social_el__des a{text-decoration:underline;font-weight:600;color:#fff;white-space:nowrap;}

/* Copyright */
.copyright{background-color:#383838;color:#fff;text-align:center;padding:1rem;}

/* Responsive style */
@media (max-width: 599px){
    .content{padding:3rem 1rem;}

    .navbar_logo{flex-grow:1;}
    .navbar_logo img{width:10rem;}
    .navbar_mobile{display:block;}
    .navbar_links{display:none;}
    .mobile{padding:2rem 1rem;}
    .mobile_close{right:1rem;}

    .footer .content{padding-top:1.5rem;padding-bottom:1.5rem;flex-wrap:wrap;}
    .footer_des{width:100%;}
    .footer_des__t1{font-size:1.5rem;}
    .footer_des__t2{font-size:1.2rem;}
    .footer_des_social_el{width:100%;margin-bottom:1rem;}
    .footer_map{display:none;}
}

@media (max-width:899px) and (min-width: 600px){
    .content{padding:4rem 2rem;}

    .navbar_logo{flex-grow:1;}
    .navbar_logo img{width:12rem;}
    .navbar_mobile{display:block;}
    .navbar_links{display:none;}
    .navbar{overflow:hidden;}

    .footer .content{padding-top:1.5rem;padding-bottom:1.5rem;flex-wrap:wrap;}
    .footer_des{width:100%;}
    .footer_des__t1{font-size:2rem;}
    .footer_des__t2{font-size:1.2rem;}
    .footer_map{display:none;}
}

@media (max-width:1199px) and (min-width: 900px){
    .content{padding:5rem 3rem;}

    .navbar_links__el{font-size:1.2rem;padding-top:.5rem;}

    .footer .content{padding-top:1.5rem;padding-bottom:1.5rem;}
    .footer_des{width:75%;}
    .footer_des__t1{font-size:2rem;}
    .footer_des__t2{font-size:1.2rem;}
    .footer_map{width:25%;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .content{padding:5rem 4rem;}

    .navbar_links__el{font-size:1.2rem;padding-top:.5rem;}

    .footer .content{padding-top:1rem;padding-bottom:1rem;}
    .footer_des{width:65%;}
    .footer_des__t1{font-size:2rem;}
    .footer_des__t2{font-size:1.2rem;}
    .footer_map{width:35%;}
}

@media (min-width:1400px){
    .content{padding:5rem 4rem;}

    .navbar_links__el{font-size:1.3rem;padding-top:.6rem;}

    .footer .content{padding-top:1rem;padding-bottom:1rem;}
    .footer_des{width:70%;}
    .footer_des__t1{font-size:2.2rem;}
    .footer_des__t2{font-size:1.3rem;}
    .footer_des{font-size:1.1rem;}
    .footer_map{width:30%;}
}