/* General */
.title{font-weight:800;color:#383838;margin-bottom:.5rem;}

/* Header */
.header{width:100%;height:100vh;position:relative;}
.header_shadow{position:absolute;top:0;left:0;width:100%;height:80vh;background:rgb(0,0,0);background:linear-gradient(180deg,rgba(0,0,0,0.6) 0%,rgba(255,255,255,0)100%);z-index:2;}
.header_bg{width:100%;height:100%;object-fit:cover;}
.header_des{position:absolute;color:#fff;top:50%;left:40%;transform:translate(-50%, -50%);z-index:3;}
.header_des__t1{font-weight:300;}
.header_des__t2{font-weight:800;text-align:end;}

/* Espacio */
.espacio{text-align:center;color:#383838;}
.espacio_bg{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;}
.espacio__t1{font-weight:300;}
.espacio__sep{width:3rem;height:.1rem;background-color:#383838;margin:auto;margin-top:1.5rem;margin-bottom:1.5rem;}
.espacio__t3{color:#6d6d6d;font-weight:300;margin:auto;margin-bottom:2.5rem;}
.espacio__link{text-decoration:none;background-color:#2bbbc2;color:#fff;width:fit-content;margin:auto;padding:.8rem 1.5rem;border-radius:1rem;}

/* Cards */
.card{display:flex;align-items:center;}
.card.procesos{flex-direction:row-reverse;}
.card_des, .card_img{width:50%;}
.card_img img{width:100%;height:auto;}
.card_des{color:#2d2c2c;}
.card_des__t1{margin-bottom:1rem;}
.card_des__t2{font-weight:500;margin-bottom:1rem;}
.card_des__t3{font-weight:300;margin-bottom:.5rem;}
.card_des__t3:last-child{margin-bottom:0;}
.card_des__dot{display:flex;align-items:center;margin-bottom:1rem;}
.card_des__dot img{margin-right:.5rem;width:1.8rem;height:auto;}
.card_des__link{text-decoration:none;background-color:#2bbbc2;color:#fff;padding:.8rem 1rem;display:inline-block;border-radius:1rem;margin-top:1rem;}

/* Nustros servicios */
.servicios_title{text-align:center;margin-bottom:1rem;}
.servicios_des{text-align:center;font-weight:300;max-width:700px;margin:auto;color:#6d6d6d;margin-bottom:3rem;}
.servicios_con{display:flex;justify-content:center;}
.servicios_el{width:calc(25% - 1rem);text-align:center;padding:1rem;margin-right:1rem;-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);border-radius:.5rem;}
.servicios_el img{height:3rem;width:auto;margin:auto;margin-bottom:1rem;}
.servicios_el__title{margin-bottom:.5rem;font-weight:600;color:#383838;}
.servicios_el__des{margin-bottom:.5rem;font-weight:300;color:#6d6d6d;}

/* Tratamientos */
.tratamientos{display:flex;align-items:center;flex-wrap:wrap;}
.tratamientos_des,.tratamientos_img{width:50%;}
.tratamientos_img img{width:100%;height:auto;}
.tratamientos_des__t2{font-weight:300;margin-bottom:1rem;color:#6d6d6d;}
.tratamientos_des__el{font-weight:300;color:#6d6d6d;margin-bottom:.2rem;display:flex;align-items:center;}
.tratamientos_des__el span{width:.3rem;height:.3rem;background-color:#2bbbc2;border-radius:100%;margin-right:.5rem;}
.tratamientos_des__el:last-child{margin-bottom:0;}

/* Instalaciones */
.instalaciones_title{text-align:center;margin-bottom:1rem;}

/* Responsive style */
@media (max-width: 599px){
    .title{font-size:1.6rem;}
    .header_des{left:2rem;font-size:3.5rem;line-height:3.5rem;transform:translate(0, -50%);}

    .espacio_content{padding-top:6rem;padding-bottom:6.8rem;}
    .espacio__t1{font-size:1.1rem;}
    .espacio__t3{font-size:1.2rem;line-height:1.5rem;max-width:450px;}

    .card{flex-wrap:wrap;}
    .card_des{width:100%;padding:3rem 1rem;}
    .card_img{width:100%;}
    .card_des__t2{font-size:1.5rem;}
    .card_des__t3{font-size:1.2rem;}

    .servicios_des{font-size:1.2rem;}
    .servicios_con{flex-wrap:wrap;}
    .servicios_el{width:100%;padding:2rem 1rem;margin-bottom:2rem;margin-right:0;}
    .servicios_el__title{font-size:1.2rem;}

    .tratamientos_des,.tratamientos_img{width:100%;}
    .tratamientos_des{padding:0 1rem 3rem 1rem;}
    .tratamientos_des__t2,.tratamientos_des__el{font-size:1.2rem;}
}

@media (max-width:899px) and (min-width: 600px){
    .title{font-size:1.8rem;}
    .header_des{font-size:5rem;line-height:5rem;}

    .espacio_content{padding-top:6rem;padding-bottom:6.8rem;}
    .espacio__t1{font-size:1rem;}
    .espacio__t3{font-size:1.2rem;line-height:1.5rem;max-width:450px;}

    .card{flex-wrap:wrap;}
    .card_des{width:100%;padding:4rem 2rem;}
    .card_img{width:100%;}
    .card_des__t2{font-size:1.5rem;}
    .card_des__t3{font-size:1.2rem;}

    .servicios_des{font-size:1.2rem;}
    .servicios_con{flex-wrap:wrap;}
    .servicios_el{width:calc(50% - 1rem);margin-bottom:1rem;}
    .servicios_el__title{font-size:1.2rem;}

    .tratamientos_des,.tratamientos_img{width:100%;}
    .tratamientos_des{padding:0 2rem 4rem 2rem;}
    .tratamientos_des__t2,.tratamientos_des__el{font-size:1.2rem;}
}

@media (max-width:1199px) and (min-width: 900px){
    .title{font-size:1.8rem;}
    .header_des{font-size:5rem;line-height:5rem;}

    .espacio_content{padding-top:6rem;padding-bottom:6.8rem;}
    .espacio__t1{font-size:1rem;}
    .espacio__t3{font-size:1.3rem;line-height:1.5rem;max-width:450px;}

    .card_des{padding:2rem 2rem 2rem 3rem;}
    .card.procesos .card_des{padding:2rem 3rem 2rem 2rem;}
    .card_des__t2{font-size:1.5rem;}
    .card_des__t3{font-size:1.2rem;}

    .servicios_des{font-size:1.2rem;}
    .servicios_el__title{font-size:1.2rem;}

    .tratamientos_des{padding:1rem 3rem;}
    .tratamientos_des__t2,.tratamientos_des__el{font-size:1.2rem;}
}

@media (max-width:1399px) and (min-width: 1200px){
    .title{font-size:2rem;}
    .header_des{font-size:5rem;line-height:5rem;}

    .espacio_content{padding-top:6rem;padding-bottom:6.8rem;}
    .espacio__t1{font-size:1rem;}
    .espacio__t3{font-size:1.2rem;line-height:1.5rem;max-width:400px;}

    .card_des{padding:2rem 2rem 2rem 4rem;}
    .card.procesos .card_des{padding:2rem 4rem 2rem 2rem;}
    .card_des__t2{font-size:1.5rem;}
    .card_des__t3{font-size:1.2rem;}

    .servicios_des{font-size:1.2rem;}
    .servicios_el__title{font-size:1.2rem;}

    .tratamientos_des{padding:1rem 4rem;}
    .tratamientos_des__t2,.tratamientos_des__el{font-size:1.2rem;}
}

@media (min-width:1400px){
    .title{font-size:2.5rem;}
    .header_des{font-size:7rem;line-height:7rem;}

    .espacio_content{padding-top:6rem;padding-bottom:6.8rem;}
    .espacio__t1{font-size:1.2rem;}
    .espacio__t3{font-size:1.5rem;line-height:1.5rem;max-width:500px;}

    .card_des{padding:3rem 3rem 3rem 8rem;}
    .card .card_des{padding:3rem 3rem 3rem 8rem;}
    .card.procesos .card_des{padding:3rem 8rem 3rem 3rem;}
    .card_des__t2{font-size:1.5rem;}
    .card_des__t3{font-size:1.2rem;}

    .servicios_des{font-size:1.3rem;}
    .servicios_el__title{font-size:1.3rem;}

    .tratamientos{justify-content:flex-end;}
    .tratamientos_des{max-width:700px;padding:0 4rem;}
    .tratamientos_des__t2,.tratamientos_des__el{font-size:1.2rem;}
}